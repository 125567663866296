import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdOutlineClose, MdOutlineCheck, MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";
import { Input, Button } from "react-daisyui"
import { useBadWordStore, BadWordStore, BadWordStoreContext } from "./store/BadWordStore";
import { observer } from "mobx-react";

const EditBadWords = observer(() => {
    const { id } = useParams();
    const store = new BadWordStore({
        isloading: false,
        editloading: true,
        id: id
      });
    return (
        <BadWordStoreContext.Provider value={store}>
            <Screen />
        </BadWordStoreContext.Provider>
    )
})

export default EditBadWords;

const Screen = observer(() => {
    const { id } = useParams();
    const { 
        name,
        status,
        keywords,
        image,
        imageurl,
        saved,
        loadCta,
        setprice,
        price,
        setProductType,
        Producttypelist,
        ProductType,
        setname,
        setstatus,
        setkeywords,
        setimage,
        editBadWord,
        setcheckbox,
        setScreen,
        Screen,
        checkbox,
        setlink,
        link,
        setcontent,
        content

      } = useBadWordStore();

      const navigate = useNavigate();
      useEffect(() => {
        if (saved) {
          navigate("../nsfwwords");
        }
      }, [saved,loadCta]);
      const handleCheckboxChange = (e) => {
        const value = e.target.checked ? 1 : 0;
        setcheckbox(value);
        setlink("")
      };

      const goBack = (() => {
        window.history.back();
      })

      const [error, setError] = useState('');

      const handleImageChange = (e) => {
        const file = e.target.files[0];
    
        // Check if a file is selected
        if (file) {
            // Create an image element to check the dimensions
            const img = new Image();
            img.onload = () => {
                const { width, height } = img;
    
                // Validate 1:1 aspect ratio (width must equal height) and minimum size (250x250)
                if (width !== height) {
                    setError('Image must have a 1:1 aspect ratio.');
                    e.target.value = '';  // Reset the input field
                    setimage(null);        // Reset the image state
                } else if (width < 250 || height < 250) {
                    setError('Image must be at least 250x250 pixels.');
                    e.target.value = '';  // Reset the input field
                    setimage(null);        // Reset the image state
                } else {
                    console.log("yeewerewrewr",file)
                    setimage(file);        // Set the image if aspect ratio and size are valid
                    setError('');           // Clear the error
                    // setimageUrl(URL.createObjectURL(file));  // Optional: Set the image URL
                }
            };
            img.src = URL.createObjectURL(file);
        }
    };

    const handleChange = (e) => {
        // Get the value from the input
        const value = e.target.value;

        // Trim both leading and trailing spaces
        const trimmedValue = value.trimStart();

        // Prevent setting the value if it's just spaces or empty
        if (trimmedValue === "") {
            setname(""); // Clear the state if the value is just spaces or empty
            return;
        }

        setname(trimmedValue); // Set the state with the trimmed value
    };

    return (
        <>
        <div className='flex justify-between'>
            <div className=" mb-4">
                <p className=" text-2xl font-semibold text-moredarkgreen">Edit NSFW Word</p>
                {/* <p className=" opacity-80 text-sm">Home /City/ Edit</p> */}
            </div>
            <div>
                <Link onClick={goBack} className="btn btn-sm bg-darkgreen text-yellow text-sm hover:bg-yellow hover:text-darkgreen ">Back</Link>
            </div>
        </div>  
            <div className="card bg-base-100 shadow-md p-6 mt-3 mb-10">
                <form onSubmit={(e) => {editBadWord(id);e.preventDefault()}}>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                        <div>
                            <label htmlFor="screen" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">NSFW Word<span className="text-[#f00]">&nbsp;*</span></label>
                            <Input
                                required
                                name="name"
                                placeholder="NSFW Word"
                                value={name}
                                onChange={handleChange}
                                id="name"
                                maxLength={25}
                                type="text"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                       
                    </div>
                    <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <Link to="/nsfwwords" className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen "><MdOutlineClose className="mr-1"/>Cancel</Link>
                        <button type="submit" className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]   ml-4" ><MdOutlineCheck className="mr-1"/>Update</button>
                    </div>
                </form>
            </div>

        </>
    )
})
